<template>
    <div class="row">
        <div class="col-md-10">
            <div class="d-flex">
                <label class="form-label w-25 align-self-end">Add client user to group</label>
                <vue3-simple-typeahead
                    style="margin-top: 7px"
                    ref="typeahead"
                    id="typeahead_id"
                    placeholder="Enter Keyword to search..."
                    class="form-control"
                    :items="search.data"
                    :itemProjection="
                        (item) => {
                            return `${item.name} (${item.client_code})`;
                        }
                    "
                    :minInputLength="0"
                    :minItemLength="0"
                    @onInput="onSearch"
                    @selectItem="onSelect"
                    @onBlur="endSearching"
                >
                    <template #list-item-text="slot">
                        <span v-html="slot.boldMatchText(slot.itemProjection(slot.item))"></span>
                    </template>
                </vue3-simple-typeahead>
        </div>
    </div>
    <div class="col-md-2 d-flex justify-content-end align-items-center">
        <button
            class="btn btn-light btn-sm"
            @click="clear"
        >
            Clear
        </button>
        <button
            class="btn btn-warning btn-sm"
            @click="addClientUserToGroup"
            :disabled="!selectedUser"
        >
            Add
        </button>
    </div>

    </div>
</template>

<script>
import {parseErrorMessage} from "../../helpers/errorHandler";
import {useToast} from "vue-toastification";

export default {
    name: "ClientUserSelector",
    props: {
        isSelectable: Boolean,
        excludeIds: {
            type: Array,
            default: []
        },
        branchId: Number,
    },
    setup(props) {
        const toast = useToast();
        return {
            toast
        }
    },
    data() {
        return {
            loading: false,
            selectedUserDisplayName: this.displayName,
            selectedUser: null,
            showSelector: false,
            search: {
                keyword: null,
                data: [],
            },
            debounce: null
        };
    },
    methods: {
        startSearching() {
            this.showSelector = true;
            this.$nextTick(() => {
                this.$refs.typeahead.focusInput();
                this.getOptions();
            });
        },
        endSearching() {
            this.showSelector = false;
        },
        onSearch(e) {
            this.search.keyword = e.input;
            this.selectedUser = null;
            this.getOptions();
        },
        onSelect(item) {
            if (this.excludeIds.includes(item.id)) {
                return;
            }

            this.selectedUser = item;
        },
        getOptions() {
            this.loading = true;
            this.search.data = [];
            clearTimeout(this.debounce);
            this.debounce = setTimeout(() => {
                axios.get(`/api/client/user/search`, {
                    params: {
                        'keyword': this.search.keyword,
                    }
                }).then(({ data }) => {
                    this.search.data = data?.data?.filter(d => !this.excludeIds.includes(d.id));
                }).catch((error) => {
                    this.toast.error(parseErrorMessage(error))
                }).finally(() => this.loading = false);
            }, 500);
        },
        addClientUserToGroup() {
            this.$emit('selected', this.selectedUser);
            this.clear();
        },
        clear() {
            this.loading = false;
            this.search.data = [];
            this.search.keyword = '';
            this.selectedUser = null;
            this.showSelector = false;

            this.$refs.typeahead.clearInput();
            this.$refs.typeahead.focusInput();
        }
    }
}
</script>

<style scoped>

</style>
