<template>
    <div v-if="items.length <= 0">
        <div class="alert alert-warning" role="alert">
            No client users found in this group
        </div>
    </div>
    <div v-else>
        <table class="table table-hover table-sm table-centered mb-0 text-nowrap document-list-table border">
            <thead class="table-primary">
            <tr>
                <th>#</th>
                <th>Branch</th>
                <th>Name</th>
                <th>Client Code</th>
                <th>Email</th>
                <th>Under</th>
                <th class="text-right">
                    <button
                        class="btn btn-danger"
                        @click="removeAllClientUsers"
                    >
                        <i class="mdi mdi-account-multiple-remove" />
                    </button>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(clientUser, index) in items">
                <td>{{ ++index }}</td>
                <td>{{ clientUser.owner?.branch?.name || '-' }}</td>
                <td>{{ clientUser.name }}</td>
                <td>{{ clientUser.client_code || '-' }}</td>
                <td>{{ clientUser.email || '-' }}</td>
                <td>{{ clientUser?.owner?.name || '-' }}</td>
                <td class="text-right">
                    <button
                        class="btn btn-danger"
                        @click="removeClientUser(clientUser)"
                    >
                        <i class="mdi mdi-account-remove" />
                    </button>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

    <div class="row mt-3">
        <div class="col-md-12">
            <client-user-selector
                :exclude-ids="form.client_user_ids"
                @selected="addClientUserToGroup"
            />

        </div>
    </div>

    <div class="row mt-3 border-top pt-3">
        <div class="col-12 d-flex justify-content-end">
            <a href="/client/user/index" class="btn btn-outline" data-test="btn-clear">Cancel</a>
            <button
                type="submit"
                class="btn btn-primary"
                data-test="btn-submit"
                @click="submit"
                :disabled="loading"
            >Save</button>
        </div>
    </div>
</template>

<script>
import select2 from "../Select2.vue";
import {useToast} from "vue-toastification";
import ClientUserSelector from "./ClientUserSelector.vue";

export default {
    name: "ClientUserManageGroup",
    props: {
        groupId: {
            type: String,
            required: true,
        },
        clientUsers: Array,
    },
    components: {
        ClientUserSelector,
        select2,
    },
    data() {
        return {
            items: this.clientUsers,
            loading: false,
            form: {
                group_id: this.groupId,
                client_user_ids: this.clientUsers.map(clientUser => clientUser.id),
            },
        }
    },
    setup(props) {
        const toast = useToast();

        return {
            toast
        }
    },
    created() {
        $(document).ready( function() {
            $("#manage-group-loading-overlay").removeClass('d-flex').addClass('d-none');
        });
    },
    watch: {
        items: {
            handler(newItems) {
                this.form.client_user_ids = newItems?.map(item => item.id);
            },
            deep: true,
            immediate: true,
        }
    },
    methods: {
        addClientUserToGroup(value) {
            this.items.push(value);
        },
        removeAllClientUsers() {
            if (confirm('Are you sure to remove all client users from the group?')) {
                this.items = [];
            }
        },
        removeClientUser(clientUser) {
            if (confirm('Are you sure to remove ' + clientUser.name + ' from the group?')) {
                this.items = this.items.filter(item => item.id !== clientUser.id);
            }
        },
        submit() {
            this.loading = true;

            axios.post('/api/client/user/group', this.form)
            .then(({ data }) => {
                this.toast.success("Updated successfully.");
            }).catch((e) => {
                const msg = e?.response?.data?.message;
                this.toast.error(msg);
            })
            .finally(() => {
                this.loading = false;
            });
        }
    }
}
</script>

<style scoped>

</style>
