import CryptoJS from 'crypto-js';

class Encryption {

    encryptData(data, salt, iv, passphrase) {
        const key = this.hashKey(passphrase, salt);
        const decodedKey = CryptoJS.enc.Base64.parse(key);
        const decodedIV = CryptoJS.enc.Base64.parse(iv);

        const encrypted = CryptoJS.AES.encrypt(
            data,
            decodedKey,
            {
                iv: decodedIV,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            }
        );

        return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
    }

    decryptData(encryptedKey, salt, iv, passphrase) {
        const key = this.hashKey(passphrase, salt);
        const decodedKey = CryptoJS.enc.Base64.parse(key);
        const decodedIV = CryptoJS.enc.Base64.parse(iv);

        const decryptedData = CryptoJS.AES.decrypt(encryptedKey, decodedKey, {
            iv: decodedIV,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
        return decryptedData.toString(CryptoJS.enc.Utf8);
    }

    hashKey(passphrase, salt) {
        const decodedSalt = CryptoJS.enc.Base64.parse(salt);
        const hash = CryptoJS.PBKDF2(passphrase, decodedSalt, {
            keySize: 256 / 32,
            iterations: 1000,
            hasher: CryptoJS.algo.SHA256
        });
        return hash.toString(CryptoJS.enc.Base64);  // Return the result as base64
    }
}

export default Encryption;
